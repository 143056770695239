import { IApplication } from '../models/application.model';
import { JobApplication } from '../models/job-application.model';
import { ApplicationJob} from '../models/job.model';

export class Application {
  jobApplication: JobApplication;
  jobInfo: ApplicationJob;
  inactiveJob?: boolean;
  candidateDataErased?: boolean;
  isUniversalJob?: boolean;
  boundToApplication?: string;

  constructor(jobApplicationData: IApplication) {
      this.jobInfo = jobApplicationData.job || jobApplicationData.universalJob;
      this.isUniversalJob = !!jobApplicationData.universalJob;
      this.inactiveJob = false;
      this.candidateDataErased = false;
      this.boundToApplication = jobApplicationData.boundToApplication?.guid;

      this.jobApplication = {
        guid: jobApplicationData.guid,
        businessQuestions: jobApplicationData.businessQuestions,
        videoQuestions: jobApplicationData.videoQuestions,
        orderOfQuestionsModules: jobApplicationData.orderOfQuestionsModules,
        applicationComplete: jobApplicationData.applicationComplete,
        currentQuestionsModule: jobApplicationData.currentQuestionsModule,
        nextQuestion: jobApplicationData.nextQuestion,
        passBusinessQuestions: jobApplicationData.passBusinessQuestions,
        videoQuestionsComplete: jobApplicationData.videoQuestionsComplete,
        numberOfSentApplicationLinkSMSs:
          jobApplicationData.numberOfSentApplicationLinkSMSs,
        candidate: {
          guid: jobApplicationData.candidate.guid,
          name: jobApplicationData.candidateAppliedWithName,
        },
        businessQuestionsScore: jobApplicationData.businessQuestionsScore,
        psykometrikaResults: jobApplicationData.psykometrikaResults,
        candidateCanEditViaReminderLink: jobApplicationData.candidateCanEditViaReminderLink
      };
  }
}
