export function endpoints(url: string, apiVersion: string) {
  return {
    api:                       `${url}/${apiVersion}`,
    candidates:                `${url}/${apiVersion}/candidates`,
    companyInfo:               `${url}/${apiVersion}/companies/context/company_landing_page`,
    companyJobs:               `${url}/${apiVersion}/companies`,
    companyCategories:         `${url}/${apiVersion}/job_categories`,
    companyLocations:          `${url}/${apiVersion}/locations`,
    companyTypesOfEmployment:  `${url}/${apiVersion}/job_types_of_employment`,
    companyTimeExtents:        `${url}/${apiVersion}/job_time_extents`,
    countries:                 `${url}/${apiVersion}/countries`,
    job:                       `${url}/${apiVersion}/jobs/page`,
    jobs:                      `${url}/${apiVersion}/jobs`,
    inhouseQuestion:           `${url}/${apiVersion}/inhouse_question`,
    applications:              `${url}/${apiVersion}/applications`,
    applicationsPage:          `${url}/${apiVersion}/applications/page`,
    psykometrika:              `${url}/${apiVersion}/psykometrika`,
    enterprises:               `${url}/${apiVersion}/enterprises`,
    enterpriseJobs:            `${url}/${apiVersion}/enterprise`,
    universalJobs:             `${url}/${apiVersion}/universal_jobs`,
    referrer:                  `${url}/${apiVersion}/referral`,
    uploadFile:                `${url}/${apiVersion}/storage/upload_file`,
    whistleBlower:             `${url}/${apiVersion}/whistle_blower_alert`,
    whistleBlowerDocument:     `${url}/${apiVersion}/whistle_blower_alert/document`,
  };
}
